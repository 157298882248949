import * as React from 'react'

import { Box, Button, Container, Divider, Flex, Grid, HStack, Heading, Link, SimpleGrid, Stack, StackDivider, Text, OrderedList, Input } from '@chakra-ui/react';
import { gql, useQuery } from '@apollo/client';
import useAuth, { User } from '../hooks/useAuth';

export const GET_CUSTOMER = gql`
  query GetCustomer($customerId: Int) {
    customer(customerId: $customerId) {
      firstName
      email
      shipping {
        address1
        address2
        city
        company
        postcode
        country
      }
      billing {
        address2
      }
      firstName
       orders {
        nodes {
            id
            status
            total
            orderKey
            orderNumber
            dateCompleted
            date
            customerNote
            currency
            paymentMethod
            paymentMethodTitle
            modified
            subtotal
            shippingTotal
            shippingTax
            orderVersion
            datePaid
            needsPayment
            needsProcessing
            needsShippingAddress
        }
    }
    }
  }
`;

const ShoppingHistory = () => {

    const { user, loggedIn } = useAuth();
    const { id, firstName, lastName, email } = user as User;

    const customer = useQuery(GET_CUSTOMER, {
        variables: { id },
    });

    return (
        <Container bg="gray.50" maxW="100%" p={{ base: '4', md: '16' }}>
             
            <Box maxW="100%" display="block">
                <Heading fontSize="2xl" py="24px" textAlign="center"> Your Shopping History </Heading>
                {customer.data?.customer.orders.nodes.length == 0 ?
                (   
                    <Text textAlign="center">You don't have any orders yet.</Text>
                ) : (
                    // <><Box fontSize="xl" my="6" display="flex" alignItems="center">
                    //     <Text id="as-root" mr="4">Track your order:</Text>
                    //     <div data-domain="" data-size="medium" className="as-track-button"></div>
                    // </Box>
                    //     <Text>*Please check your email in order to find tracking number</Text></>
                    <HStack><Box w="100%" id="tm-my-tp-container" data-key="1qRX1ZieyN1qlpdpa25nmJpl3aOSy5VXp88="></Box></HStack>
                )}
                
                <SimpleGrid columns={{ lg: 4, md: 3, sm: 2 }} spacing={6}> 
                    {/* {customer.data?.customer.orders.nodes.length == 0 && <>You don't have any orders yet.</>} */}
                    {customer.data?.customer.orders.nodes.map((e: any) => (
                        <Box backgroundColor="white" p="8" marginInlineStart="0px !important" mb="16px" w={{md: "auto", base:"100%"}} boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)" borderRadius="8px">
                            
                            <Heading size='md' pb="24px">Order Number: {e.orderNumber}</Heading>
                            <Stack divider={<StackDivider />} spacing='4'>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>Status</Heading>
                                    {e.status === "PROCESSING" &&  <><Text color="orange" pt='2' fontSize='sm'>
                                        {e.status}
                                    </Text></>}
                                     {e.status === "FAILED" &&  <><Text color="red" pt='2' fontSize='sm'>
                                        {e.status}
                                    </Text></>}
                                     {e.status === "CANCELLED" &&  <><Text color="red" pt='2' fontSize='sm'>
                                        {e.status}
                                    </Text></>}
                                    {e.status === "COMPLETED" &&  <><Text color="green" pt='2' fontSize='sm'>
                                        {e.status}
                                    </Text></>}
                                    {e.status === "PENDING" &&  <><Text color="orange" pt='2' fontSize='sm'>
                                        {e.status} PAYMENT
                                    </Text></>}
                                      {e.status === "ON_HOLD" &&  <><Text color="orange" pt='2' fontSize='sm'>
                                        {e.status} 
                                    </Text></>}
                                    {/* {e.status && <Text pt='2' fontSize='sm'> {e.status}</Text>} */}
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>Needs payment?</Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {e.needsPayment ? "Yes" : "No"}
                                    </Text>
                                </Box>
                                {e.needsPayment ? "" : <> <Box>
                                    <Heading size='xs' textTransform='uppercase'>Date paid</Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {e.datePaid}
                                    </Text>
                                </Box></>}
                            
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>Payment Info</Heading>
                                    <Text pt='2' fontSize='sm'> Total: {e.total}</Text>
                                    <Text pt='2' fontSize='sm'> Shipping total: {e.shippingTotal}</Text>
                                    <Text pt='2' fontSize='sm'> Subtotal: {e.subtotal}</Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>Payment Method</Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {e.paymentMethodTitle}
                                    </Text>
                                </Box>
                                {e.dateCompleted ? <><Box>
                                    <Heading size='xs' textTransform='uppercase'>Date Completed</Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {e.dateCompleted}
                                    </Text>
                                </Box></> : ""}
                                
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>Needs processing?</Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {e.needsProcessing ? "yes" : "no"}
                                    </Text>
                                </Box>
            
                                
                            </Stack>
                        </Box>
                    ))}
                </SimpleGrid>
            </Box>
        </Container>
    )
}

export default ShoppingHistory