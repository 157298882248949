import * as React from "react";

import AuthContent from '../components/Login/AuthContent';
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import ShoppingHistory from '../components/Account/ShoppingHistory';
import { PageProps, graphql } from "gatsby";
import { Text } from '@chakra-ui/react'
import { FunctionComponent } from "react";
import { Helmet } from 'react-helmet';

const Shoppinghistory = () => {
  
  return (
    <Layout>
      {/* <Helmet>
            <script type="text/javascript" src="//button.aftership.com/all.js">
                {`(function(e,t,n){var r,i=e.getElementsByTagName(t)[0];
                  if(e.getElementById(n))return;
                  r=e.createElement(t);
                  r.id=n;
                  r.src="//button.aftership.com/all.js";
                  i.parentNode.insertBefore(r,i)})(document,'script','aftership-jssdk' )`}
            </script>
          </Helmet> */}
        <Helmet><script async src="https://s.trackingmore.com/track/js/20210111.js"></script></Helmet>
      <AuthContent>
        <ShoppingHistory></ShoppingHistory>
      </AuthContent>
    </Layout>
  );
}


export default Shoppinghistory

export const Head = () => (
  <SEO title="AceMedical Wholesale | Shopping History" description="Your shopping history." />
)
